import type { Action } from 'svelte/types/runtime/action';

const clickOutside: Action<HTMLElement, () => void> = (node, handler) => {
    const handleClick = (event: MouseEvent): void => {
        if (event.target instanceof Element && !node.contains(event.target) && !event.defaultPrevented) {
            handler?.();
        }
    };

    document.addEventListener('click', handleClick, true);

    return {
        destroy(): void {
            document.removeEventListener('click', handleClick, true);
        },
    };
};

export default clickOutside;
