<script lang="ts">
    import clickOutside from 'Lib/Utils/ClickOutside';

    let isDropDownOpen: boolean;
</script>

<section
    class="dropdown"
    use:clickOutside={() => {
        isDropDownOpen = false;
    }}>
    <div on:click={() => (isDropDownOpen = !isDropDownOpen)}>
        <slot name="toggle" />
    </div>
    <div
        class="dropdownContent"
        class:show={isDropDownOpen}>
        <slot name="dropdown" />
    </div>
</section>

<style>
    .dropdown {
        position: relative;
        display: inline-block;
        direction: rtl;
    }

    .dropdownContent {
        color: black;
        display: none;
        position: absolute;
        background-color: #f6f6f6;
        min-width: 150px;
        max-width: 500px;
        min-height: 64px;
        border: 1px solid #ddd;
        z-index: 1;
        direction: ltr;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
    }

    .show {
        display: block;
    }
</style>
